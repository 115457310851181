import _typeof from "@babel/runtime/helpers/typeof";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useCallback, useState } from 'react';
import { getCoefficientByAmount, getCoefficientByDate } from '../../helpers/index';

var useCalculator = function useCalculator(_ref) {
  var minDeposit = _ref.minDeposit;

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      hasError = _useState2[0],
      showError = _useState2[1];

  var _useState3 = useState(minDeposit),
      _useState4 = _slicedToArray(_useState3, 2),
      value = _useState4[0],
      handleValue = _useState4[1];

  var _useState5 = useState('date_1'),
      _useState6 = _slicedToArray(_useState5, 2),
      dateID = _useState6[0],
      handleDateID = _useState6[1];

  var _useState7 = useState(''),
      _useState8 = _slicedToArray(_useState7, 2),
      amount = _useState8[0],
      handleAmount = _useState8[1];

  var updateValue = useCallback(function (e) {
    var value = _typeof(e) === 'object' && e !== null ? e.target.value : e;
    var onlyNumbers = /^\d+(\.\d{0,2})?$/;
    if (!onlyNumbers.test(value)) value = value.slice(0, value.length - 1);
    if (value.length > 6) value = value.slice(0, 6);
    handleValue(value);
  }, []);
  var updateDateValue = useCallback(function (e) {
    handleDateID(e.target.value);
  }, []);

  var validateMinDeposit = function validateMinDeposit() {
    var isError = Number(value) < minDeposit;
    showError(isError);
    return isError;
  };

  var calculateAmount = useCallback(function () {
    if (Number(value) < minDeposit) {
      showError(true);
      return handleAmount('');
    }

    var result = Number(value) * getCoefficientByAmount(Number(value), minDeposit);
    handleAmount(parseFloat(result.toFixed(2)));
    showError(false);
  }, [value]);
  var calculateAmountByDate = useCallback(function () {
    if (Number(value) < minDeposit) {
      showError(true);
      return handleAmount('');
    }

    var coefficient;

    if (dateID === 'date_1') {
      coefficient = 3.4;
    } else if (dateID === 'date_2') {
      coefficient = 3.9;
    } else if (dateID === 'date_3') {
      coefficient = 4.4;
    } else {
      coefficient = 4.9;
    }

    var result = Number(value) * coefficient;
    handleAmount(parseFloat(Math.round(result)));
    showError(false);
  }, [value, dateID]);
  var handleEnterKeyPress = useCallback(function (e) {
    if (e.key === 'Enter') calculateAmount();
  }, [value]);
  var handleEnterKeyPressDate = useCallback(function (e) {
    if (e.key === 'Enter') calculateAmountByDate();
  }, [value]);
  var validateOnInput = useCallback(function (_ref2) {
    var target = _ref2.target;
    var value = target.value;
    var onlyNumbers = /^\d+(\.\d{0,2})?$/;
    if (!onlyNumbers.test(value)) target.value = value.slice(0, value.length - 1);
    if (value.length > 9) target.value = value.slice(0, 9);
  }, []);
  return {
    value: value,
    updateValue: updateValue,
    dateID: dateID,
    updateDateValue: updateDateValue,
    hasError: hasError,
    handleEnterKeyPress: handleEnterKeyPress,
    handleEnterKeyPressDate: handleEnterKeyPressDate,
    validateOnInput: validateOnInput,
    calculateAmount: calculateAmount,
    calculateAmountByDate: calculateAmountByDate,
    amount: amount,
    validateMinDeposit: validateMinDeposit
  };
};

export default useCalculator;